import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import iosShareLogo from '../../assets/img/ios-share.png';

function IosDialog(props) {
  
  const { onClose, ...other } = props;
  
  function handleClose() {
    onClose();
  }
  
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title" {...other}
      maxWidth={'sm'}
      fullWidth={true}
      scroll={'paper'}
    >
      <DialogTitle id="simple-dialog-title">
        Pro Tip!
      </DialogTitle>
      
      <DialogContent>
        <DialogContentText>
          Do you know you can install this App into your phone, and it is quite
          easy.
          Just tap on <img style={{width: '24px', height: '24px'}} src={iosShareLogo} alt="ios-share-logo"/> and then
          select {'"Add to Home Screen"'} option;
        </DialogContentText>
      </DialogContent>
  
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
      
    </Dialog>
  );
}

IosDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

IosDialog.defaultProps = {
  open: false,
};

export default IosDialog;
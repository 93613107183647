window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

// Detects if device is on iOS
window.isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test( userAgent );
};
// Detects if device is in standalone mode
window.isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

// make the prompt to appear in android chrome browser.
window.addEventListener('beforeinstallprompt', (e) => {
  e.prompt();
  e.userChoice
    .then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the Add prompt'); //eslint-disable-line
      } else {
        console.log('User dismissed the A2HS prompt'); //eslint-disable-line
      }
    });
});
import React, { useState } from 'react';
import './scss/Homepage.scss';
// import data from '../../data/countryCodes';
import { WHATSAPP_API_URL as url } from '../../constants/constants';
import isEmpty from 'validator/lib/isEmpty';
import { toast } from 'react-toastify';
import CountryDialog from '../Common/CountryDialog';
import IosDialog from '../Common/IosDialog';
import { DAYS_TO_SHOW_IOS_DIALOG } from '../../constants/constants';


const Homepage = () => {
  
  const [ countryCode, setCountryCode ] = useState('');
  const [ phoneNumber, setPhoneNumber ] = useState('');
  const [ openDialog, setOpenDialog ] = useState(false);
  const [ openIosDialog, setOpenIosDialog ] = useState(false);
  
  // Checks if should display install popup notification:
  if (window.isIos() && !window.isInStandaloneMode()) {
    const requiredMilliSeconds = DAYS_TO_SHOW_IOS_DIALOG * 24 * 60 * 60 * 1000;
    const lastShowTime = new Date(JSON.parse(localStorage.getItem('lastShow'))).getTime();
    const todayTime = new Date().getTime();
    if (!lastShowTime) {
      showIosDialog();
    } else if (( todayTime - lastShowTime ) > requiredMilliSeconds) {
      showIosDialog();
    }
  }
  
  function showIosDialog() {
    setOpenIosDialog(true);
    // store user's decision to not to display that for next x days
    localStorage.setItem('lastShow', JSON.stringify(new Date()));
  }
  
  function handleIosDialogClose() {
    setOpenIosDialog(false);
  }
  
  function handleDialogOpen() {
    setOpenDialog(true);
  }
  
  const handleDialogClose = value => {
    setOpenDialog(false);
    if (!value) return;
    setCountryCode(Number(value));
  };
  
  const handleCountryCodeChange = (e) => {
    let value = e.target.value;
    if (!value) value = '';
    setCountryCode(value);
  };
  const handlePhoneChange = (e) => {
    setPhoneNumber(e.target.value);
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    let countryCode1 = '' + countryCode;
    let phoneNumber1 = '' + phoneNumber;
    if (isEmpty(countryCode1) || isEmpty(phoneNumber1)) {
      toast.error('Please provide country code and phone number');
      return;
    }
    const messageUrl = `${url}${countryCode1}${phoneNumber1}`;
    // window.location.href = `${url}${countryCode}${phoneNumber}`;
    window.open(messageUrl);
    // https://api.whatsapp.com/send?phone={country code}{numbers}
  };
  
  return (
    <div className="container">
      <div className="row">
        <div className="col col-12">
          <p>
            Input country code and the registered whatsapp number, you may
            initiate whatsapp messaging to that number without saving it into
            your contact list.
            <br/>
            You may also click on the  &quot;+&quot; to choose country code
            from a list.
            <br/>
            E.g. to initiate a chat to a Hong Kong number,
            type &quot;852&quot; or pick &quot;Hong Kong&quot;, then input
            the phone number such as &quot;98765432&quot;,
            and &quot;Send&quot;.
          </p>
        </div>
      </div>
      
      <div className="row">
        <div className="col col-1">
          <button
            className="btn btn-plus"
            onClick={handleDialogOpen}
          >+
          </button>
        </div>
        <div className="col col-3">
          <input
            className="form-control" type="number"
            pattern="\d*"
            value={countryCode}
            onChange={handleCountryCodeChange}
          />
        </div>
        <div className="col col-8">
          <input
            className="form-control" type="number"
            pattern="\d*"
            value={phoneNumber}
            onChange={handlePhoneChange}
          />
        </div>
      </div>
      
      <div className="row">
        <div className="col col-12">
          <button
            className="btn btn-send"
            onClick={handleSubmit}
          >Send
          </button>
        </div>
      </div>
      
      <CountryDialog
        onClose={handleDialogClose}
        selectedValue={null}
        open={openDialog}
      />
      
      <IosDialog
        onClose={handleIosDialogClose}
        open={openIosDialog}
      />
    </div>
  );
};

export default Homepage;

import React from 'react';
import { render } from 'react-dom';
import configureStore, { history } from './store/configureStore';
import { sessionService } from 'redux-react-session';
// import axios from 'axios'; //this library will be used to send requests to server if required
import * as serviceWorker from './serviceWorker';
import App from './components/App';
import 'react-toastify/dist/ReactToastify.css';
import './assets/scss/style.scss'; // Yep, that's right. You can import SASS/CSS files too! npm package node-sass will process it by itself
import './assets/js/script';
// axios.defaults.withCredentials = true;

const store = window.store = configureStore(); // to get a view of store at any point, attach it to windows object.
// const store = configureStore();
sessionService.initSessionService(store, {
  refreshOnCheckAuth: true, redirectPath: '/', driver: 'LOCALSTORAGE'
});

render(
  <App store={store} history={history} />,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
serviceWorker.register();
import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
// import { sessionReducer } from 'redux-react-session';
// import itemsReducer from "./itemsReducer";


const rootReducer = combineReducers({
  routing: routerReducer,
  // session: sessionReducer,
  // items: itemsReducer,
  // loadingItems: loadingItemsReducer,
});

export default rootReducer;
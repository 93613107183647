import React from 'react';
// import PropTypes from 'prop-types';
import './scss/Header.scss';

const Header = (props) => { //eslint-disable-line no-unused-vars
  
  return(
    <header>
      <div className="container">
        <div className="text-center">
          <h3>FastsApp</h3>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  // actions: PropTypes.object.isRequired,
};

export default Header;

import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Header from './components/Common/Header';
import Homepage from './components/Homepage/Homepage';
import NotFoundPage from './components/NotFoundPage/NotFound';
import * as routes from './constants/routePaths';
//---DEMOS---
import Menu from './components/Demo/Menu';
import Dialog from './components/Demo/Dialog';

class CustomRoutes extends Component {
  render() {
    return (
      <Router>
        <div>
          <Header />
          <Switch>
            <Route exact path={routes.HOME} component={Homepage} />
  
            <Route exact path="/menu" component={Menu} />
            <Route exact path="/dialog" component={Dialog} />

            {/* Must be at last to render not found page if nothing above is matched*/}
            <Route component={NotFoundPage} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default CustomRoutes;
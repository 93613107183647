import { createStore, compose, applyMiddleware } from 'redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import { createBrowserHistory } from 'history';
// 'routerMiddleware': the new way of storing route changes with redux middleware since rrV4.
import { routerMiddleware } from 'react-router-redux';
import rootReducer from '../reducers';

// redux persist to get store back after page refresh
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
// redux-logic implementation
import { createLogicMiddleware } from 'redux-logic';
import arrLogic from '../reduxLogic/index';

const persistConfig = {
  key: 'root',
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);


const deps = { // optional injected dependencies for logic
               // anything you need to have available in your logic
               // A_SECRET_KEY: 'dsfjsdkfjsdlfjls',
               // firebase: firebaseInstance
};
const logicMiddleware = createLogicMiddleware(arrLogic, deps);

export const history = createBrowserHistory();

function configureStoreProd(initialState) {
  const reactRouterMiddleware = routerMiddleware(history);
  const middlewares = [
    // Add other middleware on this line...
    logicMiddleware,
    reactRouterMiddleware,
  ];
  
  return createStore(persistedReducer, initialState, compose(
    // return createStore(rootReducer, initialState, compose(
    applyMiddleware(...middlewares)
    )
  );
}

function configureStoreDev(initialState) {
  const reactRouterMiddleware = routerMiddleware(history);
  const middlewares = [
    // Add other middleware on this line...
    // Redux middleware that spits an error on you when you try to mutate your state either inside a dispatch or between dispatches.
    reduxImmutableStateInvariant(),
    logicMiddleware,
    reactRouterMiddleware,
  ];
  
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools
  const store = createStore(
    persistedReducer,
    // rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );
  return store;
}

/**
 *- From create-react-app docs -
 https://facebook.github.io/create-react-app/docs/adding-custom-environment-variables
 
 There is also a special built-in environment variable called NODE_ENV.
 You can read it from process.env.NODE_ENV. When you run npm start, it is
 always equal to 'development', when you run npm test it is always equal
 to 'test', and when you run npm run build to make a production bundle,
 it is always equal to 'production'. You cannot override NODE_ENV manually.
 This prevents developers from accidentally deploying a slow development build
 to production.
 */
const configureStore = process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev;

export default configureStore;
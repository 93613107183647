import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import data from '../../data/countryCodes';
// import { makeStyles } from '@material-ui/core/styles';


function CountryDialog(props) {
  
  const { onClose, selectedValue, ...other } = props;
  
  function handleClose() {
    onClose(selectedValue);
  }
  
  function handleListItemClick(value) {
    onClose(value);
  }
  
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title" {...other}
      maxWidth={'sm'}
      fullWidth={true}
      scroll={'paper'}
    >
      <DialogTitle id="simple-dialog-title">
        Select A Country
      </DialogTitle>
      
      <DialogContent>
        <List>
          {data.map(country => (
            <ListItem button onClick={() => handleListItemClick(country.dial_code)} key={`${country.code}${country.dial_code}`}>
              <ListItemText primary={country.name} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
}

CountryDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  selectedValue: PropTypes.string,
};

CountryDialog.defaultProps = {
  selectedValue: null,
  open: false,
};

export default CountryDialog;
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomRoutes from '../routes';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ToastContainer } from 'react-toastify';

class App extends Component {
  render() {
    const { store, history } = this.props; //eslint-disable-line
    let persistor = persistStore(store);
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <React.Fragment>
            {/* Material-UI provides a CssBaseline component to kickstart
            an elegant, consistent, and simple baseline to build upon.
            CssBaseline provides reset styles to browser defaults */}
            {/* CssBaseline resets the browsers default styles just like normalize.css do */}
            <CssBaseline/>
            <div id="wrapper">
              <ToastContainer/>
              <CustomRoutes/>
            </div>
          </React.Fragment>
        
        </PersistGate>
      </Provider>
    );
  }
}

App.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};
export default App;
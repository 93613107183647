import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <section className="section section-not-found">
      <div className="container">
        <div className="col s12">
          <h1>404 Page Not Found</h1>
          <p className="flow-text">
            We are sorry, the page you are looking for has been deleted or
            permanently moved.
          </p>
          <p>
            <Link to="/" className="btn-large">Back to Home</Link>
          </p>
        </div>
      </div>
    </section>
  );
};

export default NotFound;